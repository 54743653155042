import React from 'react';

export default function Resultado({empresa, style}) {
	
	const url = `/clients/${empresa.id}`
	return (
		<div className="card" style={style}>
			<div className="d-flex justify-content-between">
				<div className="my-2">
					<h3 className="card-title my-1">
						{empresa.razon_social}
					</h3>
					<h4>RUC: {empresa.ruc}</h4>
				</div>
				<a href={url} className='btn btn-primary my-3 mr-2' style={{height: "35px"}}>VER</a>
			</div>
		</div>
	)
}
import React, {useState, useEffect} from "react"
import Resultados from './Resultados'

function Buscador() {
  const [busqueda, setBusqueda] = useState('')
  const [resultados, setResultados] = useState([])
  const [cargando, setCargando] = useState(false)
  
  useEffect(() => {
  	if (busqueda.length >= 3) {
  		const url = `https://crm-tfc.tfc.com.ec/clients/buscador?busqueda=${busqueda}`
  		setCargando(true)
  		fetch(url)
  		.then(response => response.json())
  		.then(data => {
			let nuevosResultados = [...resultados];
			nuevosResultados = data
			setCargando(false)
			setResultados(nuevosResultados)
  		}) 
  	} else {
  			setResultados([])
  	}
  },[busqueda])

  const handleChange = (e) => {
    let nuevaBusqueda = e.target.value
    setBusqueda(nuevaBusqueda)
  }

    return (
      <React.Fragment>
	      <div className="row">
	      	<div className="col-md-12 mt-4">
						<form onSubmit={(e)=> {this.preventDefault(e)}}>
							<fieldset>
							<div className="row">
								<div className="col-md-3">
									<legend>Razón Social:</legend>
								</div>
								<div className="col-md-9">
									<input onChange={handleChange} type="text" className='form-control'/>
								</div>
							</div>
							</fieldset>
						</form>
		      </div>
	      </div>
	      <Resultados
	      	resultados={resultados}
	      	cargando={cargando}
	      	busqueda={busqueda}
	      />
	    </React.Fragment>
    );
 
}
export default Buscador;

import React from "react"
import Resultado from './Resultado'
import { FixedSizeList as List } from "react-window";

export default function Resultados({busqueda, resultados, cargando}) {
	console.log(resultados)
	let resultadosDisponibles = () => {
		if (cargando == true) return (
			<div className="spinner">
			  <div className="rect1"></div>
			  <div className="rect2"></div>
			  <div className="rect3"></div>
			  <div className="rect4"></div>
			  <div className="rect5"></div>
			</div>
		)
			
		if (resultados.length == 0) return (
			<div className="card text-center">
				<h3 className="card-title my-2">
					{busqueda == '' || busqueda.length < 3 ? 'Por favor digitar mínimo 3 letras' : 'No hay resultados' }
				</h3>
			</div>	
		)

		
		// else display list
		const Row = ({ index, style }) => (
		  <Resultado
        style={style}
        key={resultados[index].id}
        empresa={resultados[index]}
      />
		);
		return (
			<List
		    itemCount={resultados.length}
		    itemSize={75}
		    height={300}
		    
		  >
		    {Row}
		  </List>
		
			// resultados.map((resultado) => (
   //      <Resultado
   //        key={resultado.id}
   //        empresa={resultado}
   //      />
   //    ))
		)
	}

	return (
		<React.Fragment> 
			<div className="row mt-4">
				<div className="col-md-12">
					{resultadosDisponibles()}
				</div>
      </div>
		</React.Fragment>
	)
}